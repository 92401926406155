.icon_color {
  color: white !important;
}
.delete_button {
  background-color: #db2828 !important;
}
.edit_button {
  background-color: #3f51b5 !important;
}

.summary_button {
  background-color:teal !important;
}
.error_message {
  font-size: 12px;
  color: #db2828;
}
.active_box {
  color: white !important;
  border-style: solid 1px #1ab394;
  border-radius: 5px;
  background-color: #1ab394;
  width: 50px;
  padding-left: 7px;
}
.inactive_box {
  color: white !important;
  border-style: solid 1px #d1dade;
  border-radius: 5px;
  background-color: #d1dade;
  width: 60px;
  padding-left: 8px;
}
.link_color {
  color: #1ab394;
  font-weight: 500;
}
.add_button {
  background-color: #1ab394 !important;
}

.cruise_header {
  min-width: 80px;
  min-height: 80px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.cruise_name {
  width: 45px;
  border-radius: 5px;
}

.cruise_deck {
  width: 50px;
  height: 17px;
  border-radius: 5px;
  background-color: #ed5565;
}

.cruise_text {
  font-size: 12px;
  font-weight: 600;
  padding: 1px;
  padding-left: 5px;
  color: white;
}

.bed_display {
  display: flex;
  justify-content: stretch;
  padding-left: 5px;
}
.bed_image {
  width: 25px;
  height: 25px;
}
.header_style {
  font-size: 13px;
  font-weight: bold;
}

.card_header_style {
  background-color: #fafafa !important;
}

.bed_active {
  color: white !important;
  background-color: #f8ac5a !important;
  border-style: solid 1px #f8ac5a;
  border-radius: 5px;
  background-color: #f8ac5a;
  width: 30px;
}

.active_tag {
  display: flex;
  justify-content: center;
}

.text_date_format{
  color: #757575 !important;
  font-size: x-small;
  font-weight: normal;
}

.cabin_information {
  display: flex;
  justify-content: center;
}

.detail_passenger {
  font-weight: bold;
  font-size: normal
}

.one_price {
  color: #1d1ab3;
  font-weight: bold;
  font-size: medium;
}

.one_price_small {
  color: #1d1ab3;
  font-weight: bold;
  font-size: 10px;
}

.two_price {
  color: #0a861f;
  font-weight: bold;
  font-size: medium;
  margin-top: 10px;
}

.two_price_small {
  color: #0a861f;
  font-weight: bold;
  font-size: 10px;
}

.airplane_active_ticket{
  color: #039717;
  font-size: 10px;
  font-weight: 300;
  font-weight: bold;
}

.airplane_inactive_ticket{
  color: #f14e4e;
  font-size: 10px;
  font-weight: 300;
  font-weight: bold;
}

.percent_label_rate{
  color: #1985CD;
  font-size: 10px;
  font-weight: 300;
  font-weight: bold;
}

.deck_small_text{
  font-size: 8px;
  color: #1d1ab3;
  font-weight: bold;
}

.cabin_text{
  font-weight: bold;
  color: #f14e4e;
}

.body_bold_table_text{
  font-weight: bold;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.table-style {
  width: 100%;
}

.th-style {
  padding: 8px;
}
.tr-style {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
}
.tr-style:nth-child(even) {
  background-color: #F3F3F4;
}

.days_family_room {
  font-size: 20px !important;
  color: #000000 !important;
  font-weight: bold !important;
}

.inactive_family_room {
  color:#DB2727 !important;
  font-weight: 500 !important;
}

.active_family_room {
  color: #569322 !important;
  font-weight: 500 !important;
}

.price_family_room {
  font-size: 16px !important;
  color: #3F85CA !important;
  font-weight: 500 !important;
}


.cabins_family_room {
  font-size: 16px !important;
  color: #E2315A !important;
  font-weight: 500 !important;
}

.labels_ticket {
  color: #000000;
  font-weight: 600;
  padding-bottom: 8px;
}

.ticket_title {
  color: #E64441;
  font-weight: 600;
}
.passenger_title {
  color: #3F85CA;
  font-weight: 600;
}

.airline_title {
  color: #569322;
  font-weight: 600;
}

.price_title {
  font-size: 18px;
}

.form_ticket_labels {
  color: #3F85CA;
  font-weight: 600;
  font-size: small;
}

.container {
  position: relative;
  width: 50%;
}

.container:hover .image {
  opacity: 0.5;
  
}

.container:hover .download {
  opacity: 1;
}

.container:hover .download_deck {
  opacity: 1;
}

.download{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.download_deck{
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.yacht_image {
  padding-left: 12%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.download_button{
  padding-left: 76%;
}

.custom-file-upload {
  background-color: rgb(25, 133, 225);
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

#file-chosen{
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.order_button {
border: "none";
background: white !important;
color: #0D0D0D !important;
font-weight: normal !important;
}
.order_button:hover {
  background: rgba(0,0,0,.005) !important ;
}

.head_date {
  width: 30%;
  display: flex;
  flex-wrap: nowrap;
  padding-right: 25px;
  justify-content: flex-end;
}

.head_cruise {
  width: 70%;
  display: flex;
  flex-wrap: nowrap;
  padding-left: 25px;
  justify-content: flex-start;
}

.head_style_title {
  font-size: 14px;
  font-weight: normal !important;
  color: white !important ;
}

.head_style_value {
  font-size: 14px;
  font-weight: bold !important;
  color: white !important ;
}

.yacht_text{
  font-size: 16px;
  font-weight: bold !important;
  padding-left: 49px !important;
  color: white !important ;
}

.end_date_text {
  padding-left: 27px !important;
}

.table_flight {
  border: 1px solid black;
  border-collapse: collapse;
}

.cb-btn:checked  {
  background-color: Green;
}



.header_rolfwittmer_li {
  padding: 0;
  float: left;
  position: relative;
  margin: 0;
  line-height: 20px;
  margin-left: 19px;
}


.header_rolfwittmer_a {
  font-size: 15px;
  line-height: 20px;
  font-family: sans-serif;
  color: #281f2b;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  padding: 39px 0 36px;
  border-bottom: 5px solid transparent;
  transition: all 0.5s ease;
  position: relative;
  text-decoration: none !important;
  margin:  0;
  list-style: none;
  cursor: pointer;

}

.header_rolfwittmer_a:hover {
  border-bottom: 5px solid #281f2b;
  color: black;
}


.sf-menu * {
  margin: 0;
  list-style: none;
  font-weight: 550;
}

ul.sf-menu> li + li {
  margin-left: 19px;
}

ul.sf-menu li > ul {
  top: 100%;
}

ul.sf-menu ul {
  background: #281f2b;
  padding: 24px 0;
}

ul.sf-menu li > ul {
  top: 100%;
}

ul.sf-menu ul {
  background: #281f2b;
  padding: 24px 0;
}

ul.sf-menu ul {
  width: 170px;
  background: #281f2b;
  padding: 24px 0;
}

.sf-menu ul {
  position: absolute;
  left: 0;
  z-index: 5000;
}

.sf-menu li {
  position: relative; 
}

ul.sf-menu ul li.firstItem {
  border: none;
}


.sf-menu li:hover > ul {
  display: block !important;
  
}
ul.sf-menu ul li a:hover {
  color: #c2d730;
}

ul.sf-menu ul li a {
  font-size: 17px;
  line-height: 20px;
  font-family: 'Ubuntu', sans-serif;
  color: #ffffff;
  display: block;
  padding: 5px 23px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

/* Second header */

.mkdf-page-header{
  width: 100%;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.mkdf-menu-area {
  display: flex;
  height: 110px;
  box-shadow: none;
  border: none;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
}

.icon-cruise-tip-top {
  width: 8%;
  padding-left: 22px; 
  padding-top: 13px;
  height: inherit;

}

.menu-cruise-tip-top{
  width: 92%;
}

.menu-black {
  display: flex;
  width: 100%;
  height: 35%;
  background-color: #2E2E2E;
}

.menu-black-right {
  display: flex;
  width: 80%;
}

.menu-black-right-items{
  display: flex;
  align-items: center;
  padding: 10px;

}

.menu-black-right-icon{
  display: flex;
  align-items: center;
  padding: 3px;
}

.menu-black-right-icon > a > img {
  height: 20px;
  width: 20px;
}

.menu-black-right-a {
  color: #ffffff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  padding: 0 0 5px 0;
}

.menu-black-right-a:hover {
  color:#ffffff;
}

.menu-black-left {
  display: flex;
  width: 20%;
  justify-content: flex-end;
  padding-right: 20px;
}

.menu-black-left-icon{
  display: flex;
  align-items: center;
  padding: 3px;
}

.menu-black-left-icon > a > img {
  height: 23px;
  width: 23px;
  background-color: white; 
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  background-size: 100% auto;
}


.main-menu-tip-top {
  display: table;
  width: 100%;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid rgba(0,0,0,.1);
  vertical-align: middle;
  list-style: none;
  margin: 0;



}

.main-menu-tip-top-row {
  display:table-row;

}

.main-menu-tip-top-cell {
  display:table-cell;
  height: 100%;
  float: none;
  text-align: center;
  border-left: 1px solid rgba(0,0,0,.1);
  box-sizing: border-box;
  position: relative;
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  outline: 0;
  padding: 24px 0 24px 0;
}

.item_text {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -.005em;
  color: #303030;
  line-height: 25px;
}


.item_text:hover {
  color: #ff681a;
}

.main-menu-tip-top > .main-menu-tip-top-row > .main-menu-tip-top-cell:hover > .second {
  height: 276px !important;
  visibility: visible !important;
  opacity: 1 !important;
  overflow: visible !important;
}

.second  {
  position: absolute;
  display: block;
  padding: 0;
  margin: 0;
  z-index: 7000;
  box-shadow: 0 5px 5px 0 rgb(0 0 0 / 3%);
} 

.inner{
  z-index: 7000;
  position: relative;
  padding-top: 25px;
}

.inner > ul {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: rgba(46,46,46,.95);
  padding: 13px 20px 15px;
  list-style: none;
  margin: 0;
  text-align: left;

}

.inner > ul > li > a >span > span {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  background: 0 0;
  border: 0;
  margin: 0;
  padding: 0 0 20px 20px;
  line-height: 25px;
}

.item_outer {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  transition: padding .2s ease-in-out;
}

.passenger_list_head {
  font-weight: bold;
  font-size: 15px;
}

.passenger_list_body {
  font-size: 14px;
}

.bottom_label {
  font-size: 11px;
  color: #3F85CA;
  font-weight: 500;
}









